import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Button, ButtonPriority, ButtonSize, SectionNotification } from 'wix-ui-tpa';
import Icons from 'wix-ui-icons-common/on-stage/Error';
import { connect } from '../../../../common/components/runtime-context';
import { IActionCreators } from '../../../../types/internal-types';
import { RootState } from '../../state';
import { classes } from './SubscriptionsListItemBass.st.css';
import { aClickPayNowCtaEvent } from '@wix/bi-logger-my-subscriptions/v2';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}

// TODO instead of this button we should have like in figma
const PayNowSectionHelper = ({ subscription, actionCreators }: Props & RuntimeProps) => {
  const { t } = useTranslation();
  const biLogger = useBi();
  const onCtaClick = () => {
    biLogger.report(
      aClickPayNowCtaEvent({
        cycle: subscription.billingStatus?.currentCycle,
        cycleAutoRenew: subscription.billingSettings?.cycleAutoRenew,
        nextBillingDate: subscription.billingStatus?.nextBillingDate?.getTime(),
        paymentMethodId: subscription.billingSettings?.paymentMethod?.id,
        previousBillingDate: subscription.billingStatus?.previousBillingDate?.getTime(),
        subscriptionId: subscription.id!,
        subscriptionStatus: subscription.status,
      }),
    );
    actionCreators.openPayNowModal(subscription.id!);
  };

  return (
    <SectionNotification type="error">
      <SectionNotification.Icon icon={<Icons />} />
      <SectionNotification.Text className={classes.payNow}>
        We couldn't process your last payment. To keep this subscription active, make sure to complete the payment by
        June 14, 2021
        {/* TODO replace with key  */}
      </SectionNotification.Text>
      <Button
        data-hook="pay-now-cta-button"
        size={ButtonSize.tiny}
        priority={ButtonPriority.basicSecondary}
        onClick={onCtaClick}
      >
        Submit Payment
        {/* TODO replace with key  */}
      </Button>
    </SectionNotification>
  );
};

const mapRuntimeToProps = (_state: RootState, _props: Props, actionCreators: IActionCreators) => {
  return {
    actionCreators,
  };
};

export default connect(mapRuntimeToProps)(PayNowSectionHelper);
